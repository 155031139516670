/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { AvailableLocales } from '../locale';

type SeoProps = {
  description?: string;
  title: string;
  lang: AvailableLocales;
};

const SEO: React.FC<SeoProps> = ({ description, lang, title }) => {
  const isDev = process.env.NODE_ENV === 'development';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="Kering foundation | %s"
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    >
      {/* OneTrust Cookies Consent Notice start for www.keringfoundation.org */}
      {!isDev && (
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script="f4bc7788-d44c-455c-8658-1b32df163352"
        ></script>
      )}
      {!isDev && (
        <script type="text/javascript">{`
        function OptanonWrapper() {}
      `}</script>
      )}
      {/* OneTrust Cookies Consent Notice end for www.keringfoundation.org */}

      {/* Google Tag Manager */}
      {!isDev && (
        <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push( {'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-T8SNWCX');
        `}</script>
      )}

      {/* Facil'iti */}
      <script>{`
        (function () {
            var fs = document.createElement("script");
            fs.setAttribute("src", "https://cdn.facil-iti.app/tags/faciliti-tag.min.js");
            fs.dataset.applicationIdentifier = "fb564470-eae4-11eb-bbf3-5149de4c6d2b";
            document.head.appendChild(fs);
        }());
      `}</script>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.oneOf(['fr', 'en'] as const).isRequired,
  title: PropTypes.string.isRequired,
};

export default SEO;
